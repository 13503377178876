import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { getAPIErrorReason } from '../helpers/common';
import { REACT_APP_APIURL } from '../global/environment';
import { setUserLoader, setUsers } from '../actions/userActions';

/**
 * @desc Users - Get Users
 * @param {*}
 */
export const getUsers = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setUserLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/admin/users`);
    const { data } = response;
    if (data) {
      dispatch(setUsers(data));
      return true;
    }
  } catch (e) {
    dispatchUserError(getAPIErrorReason(e) || 'Unable to get users please try again', dispatch);
    return false;
  } finally {
    dispatch(setUserLoader(false));
  }
};

function dispatchUserError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set Subscription Loader
 */
export const setSubscriptionLoader = createAction(Actions.SET_SUBSCRIPTION_LOADER);
/**
 * @desc Set Company Subscriptions
 */
export const setCompanySubscriptions = createAction(Actions.SET_COMPANY_SUBSCRIPTIONS);
/**
 * @desc Set Subscriptions
 */
export const setSubscriptions = createAction(Actions.SET_SUBSCRIPTIONS);
/**
 * @desc set Subscription Plans
 */
export const setSubscriptionPlans = createAction(Actions.SET_SUBSCRIPTION_PLANS);
/**
 * @desc set Subscription Item
 */
export const setSubscriptionItem = createAction(Actions.SET_SUBSCRIPTION_ITEM);
/**
 * @desc update Subscription Item
 */
export const updateSubscriptionItem = createAction(Actions.UPDATE_SUBSCRIPTION_ITEM);

import { combineReducers } from 'redux';
import { createReducer as createReducerOrig } from '@reduxjs/toolkit';
import moment from 'moment';
import { createReducer } from '../helpers/reduxHelpers';

import * as Actions from '../actions/types';

const subscriptionLoaderReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_SUBSCRIPTION_LOADER
});

const companySubscriptionsReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_COMPANY_SUBSCRIPTIONS
});

const subscriptionsReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_SUBSCRIPTIONS
});

const subscriptionPlansReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_SUBSCRIPTION_PLANS
});

const initialSubscriptionState = {
  companyId: '',
  start: new Date(),
  end: new Date(moment().add(1, 'months').calendar()),
  planName: '',
  planDescription: '',
  amount: '',
  discount: 0,
  taxPercent: 0,
  status: '',
  paymentMode: '',
  paymentReference: '',
  promoCode: '',
  isPhoneCall: false,
  users: ''
};
const subscriptionItemReducer = createReducerOrig(initialSubscriptionState, (builder) => {
  builder
    .addCase(Actions.SET_SUBSCRIPTION_ITEM, (state = initialSubscriptionState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_SUBSCRIPTION_ITEM, (state, action) => {
      const subscription = { ...state };
      subscription[action.payload.propsName] = action.payload.value;
      return { ...subscription };
    });
});

const subscriptionReducer = combineReducers({
  companySubscriptions: companySubscriptionsReducer,
  loading: subscriptionLoaderReducer,
  list: subscriptionsReducer,
  plans: subscriptionPlansReducer,
  subscriptionItem: subscriptionItemReducer
});

export default subscriptionReducer;

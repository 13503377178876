import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Avtar from '../../components/Avtar/Avtar';
import Table from '../../components/Table/Table';
import { getGravatarURL } from '../../helpers/common';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { getUsers } from '../../services/userServices';
import CompanyDetailModal from '../Workspaces/companyDetailModal';
import AddSubscriptionModal from '../Subscriptions/AddSubscriptionModal';
import { getSubscriptionsByCompany } from '../../services/subscriptionServices';
import { CellLinkWrapper, CellWrapper, Name } from './styles';
import UserDetailModal from './userDetailModal';
const SORTED_FEILDS = 'sortedFeilds';
const TABNAME = 'users';
const Users = () => {
  const [isOpenUserDetailModal, setIsOpenUserDetailModal] = useState(false);
  const [isOpenCompanyDetailModal, setIsOpenCompanyDetailModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [isOpenAddSubscriptionModal, setIsOpenAddSubscriptionModal] = useState(false);
  const [itemId, setItemId] = useState();
  const userSelector = useSelector((state) => state.user);
  const { users } = userSelector;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const tableColumns = useMemo(() => {
    return [
      { Header: 'Email', accessor: 'email' },
      { Header: 'Phone', accessor: 'phone' },
      { Header: 'User Type', accessor: 'userType' },
      { Header: 'Mobile Session Count', accessor: 'mobileSessionCount' },
      { Header: 'Web Session Count', accessor: 'webSessionCount' }
    ];
  }, []);

  const sortedFeild = useMemo(() => {
    const sortedFeild = UserPreferenceSingleton.getInstance().getSettingValue(TABNAME, SORTED_FEILDS);
    return sortedFeild;
  }, []);

  const initialStateOfTable = useMemo(() => {
    if (sortedFeild?.fieldId && sortedFeild?.desc !== undefined) {
      const initialState = {
        sortBy: [
          {
            id: sortedFeild.fieldId,
            desc: sortedFeild.desc
          }
        ]
      };
      return initialState;
    }
    return undefined;
  }, [sortedFeild?.desc, sortedFeild?.fieldId]);

  const onOpenUserDetailModal = useCallback((item) => {
    setIsOpenUserDetailModal(true);
    setSelectedUser(item);
  }, []);

  const onCloseUserDetailModal = useCallback(() => {
    setIsOpenUserDetailModal(false);
  }, []);

  const onOpenCompanyDetailModal = useCallback((item) => {
    setIsOpenCompanyDetailModal(true);
    setSelectedCompany(item);
  }, []);

  const onCloseCompanyDetailModal = useCallback(() => {
    setIsOpenCompanyDetailModal(false);
  }, []);

  const tableData = useMemo(() => {
    const columns = [
      {
        Header: () => (
          <CellWrapper>
            <Name>Name</Name>
          </CellWrapper>
        ),
        accessor: 'name',
        Cell: (props) => {
          const gravatar = getGravatarURL(props?.cell?.row?.original?.email);
          return (
            <CellWrapper>
              <Avtar imgSrc={gravatar} />
              <CellLinkWrapper
                to={{ pathname: `` }}
                onClick={() => {
                  onOpenUserDetailModal(props?.cell?.row?.original);
                }}>
                {props?.cell?.row?.original.name}
              </CellLinkWrapper>
            </CellWrapper>
          );
        }
      },
      ...tableColumns
    ];

    const data = users?.map((user) => {
      const rowData = {
        id: user.id,
        name: user?.name,
        email: user?.email,
        mobileSessionCount: user?.mobileSessionCount,
        webSessionCount: user?.webSessionCount,
        userType: user?.userType,
        phone: user?.phone
      };
      return rowData;
    });
    return {
      columns,
      data
    };
  }, [onOpenUserDetailModal, tableColumns, users]);

  const onOpenAddSubscriptionModal = useCallback(() => {
    setIsOpenAddSubscriptionModal(true);
  }, []);

  const onCloseAddSubscriptionModal = useCallback(() => {
    setIsOpenAddSubscriptionModal(false);
  }, []);

  const onSaveSubscription = useCallback(() => {
    dispatch(getSubscriptionsByCompany(selectedCompany?.id));
    onCloseAddSubscriptionModal();
  }, [dispatch, onCloseAddSubscriptionModal, selectedCompany?.id]);
  const onSelectItem = useCallback(
    (item) => {
      setItemId(item?.id);
      onOpenAddSubscriptionModal();
    },
    [onOpenAddSubscriptionModal]
  );
  return (
    <>
      <Table
        columns={tableData?.columns || []}
        data={tableData?.data || []}
        tabName='users'
        initialState={initialStateOfTable}></Table>
      <Modal
        open={isOpenUserDetailModal}
        onClose={onCloseUserDetailModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'>
        <UserDetailModal
          onClose={onCloseUserDetailModal}
          user={selectedUser}
          openCompanyDetail={onOpenCompanyDetailModal}
        />
      </Modal>
      <Modal
        open={isOpenCompanyDetailModal}
        onClose={onCloseCompanyDetailModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        BackdropProps={{ invisible: true }}>
        <CompanyDetailModal
          onClose={onCloseCompanyDetailModal}
          company={selectedCompany}
          openAddSubscriptionModal={onOpenAddSubscriptionModal}
          onSelectItem={onSelectItem}
        />
      </Modal>
      <Modal
        open={isOpenAddSubscriptionModal}
        onClose={onCloseAddSubscriptionModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        BackdropProps={{ invisible: true }}>
        <AddSubscriptionModal
          onSave={onSaveSubscription}
          onClose={onCloseAddSubscriptionModal}
          company={selectedCompany}
          itemId={itemId}
        />
      </Modal>
    </>
  );
};

export default Users;

import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { getAPIErrorReason } from '../helpers/common';
import { REACT_APP_APIURL } from '../global/environment';
import { setCompanyItem, setCompanyList, setCompanyLoader, setUserCompanies } from '../actions/companyActions';

/**
 * @desc Company - Get Company List
 * @param {*} user_id
 */
export const getCompanyList = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setCompanyLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/admin/company`);
    const { data } = response;
    if (data) {
      dispatch(setCompanyList(data));
      return true;
    }
  } catch (e) {
    dispatchCompanyError(getAPIErrorReason(e) || 'Unable to get company list please try again', dispatch);
    return false;
  } finally {
    dispatch(setCompanyLoader(false));
  }
};

/**
 * @desc Company - Get Companies By User
 * @param {*} user_id
 */
export const getCompaniesByUser = (user_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setCompanyLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/admin/company/GetByUser/${user_id}`);
    const { data } = response;
    if (data) {
      dispatch(setUserCompanies(data));
      return true;
    }
  } catch (e) {
    dispatchCompanyError(getAPIErrorReason(e) || 'Unable to get companies by user please try again', dispatch);
    return false;
  } finally {
    dispatch(setCompanyLoader(false));
  }
};

/**
 * @desc Company - Get Company Item
 * @param {*} user_id
 */
export const getCompanyItem = (company_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setCompanyLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/admin/company/${company_id}`);
    const { data } = response;
    if (data) {
      dispatch(setCompanyItem(data));
      return true;
    }
  } catch (e) {
    dispatchCompanyError(getAPIErrorReason(e) || 'Unable to get Company item please try again', dispatch);
    return false;
  } finally {
    dispatch(setCompanyLoader(false));
  }
};

function dispatchCompanyError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

export const USER_PREFERENCES = 'UserPreferences';
export const REQUEST_CANCEL_MESSAGE = 'CANCEL';

export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'h:mm A';
export const TOOLTIP_DATE_FORMAT = 'DD/MM/YYYY h:mm A';

export const SUBSCRIPTION_TYPE = {
  MONTHLY: 1,
  YEARLY: 2,
  LIFE_TIME: 3
};

export const SUBSCRIPTION_PLAN_STATUS = {
  ACTIVE: 'ACTIVE',
  UNPAID: 'UNPAID',
  CANCELED: 'CANCELED',
  ENDED: 'ENDED'
};
